*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style:none;
  text-decoration: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant:#2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant:rgba(77,181,255,0.4);
  --color-white: #fff;
  --color-light:rgba(255,255, 255,0.6);
  --container-width-lg:75%;
  --container-width-md:86%;
  --container-width-sm:90%;
  --transition: all 400ms ease;
}
html { 
  scroll-behavior:smooth;
    
}
::-webkit-scrollbar{
  display: none;
}
body{
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height:1.7;
  /* background-image:url(../src/Assets/texture1.jpg); */
  background-color: #121213;

}