* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Josefin Sans', sans-serif;
}
.about{
    width: 100%;
    height: 100%;
    background-color: #121213;
    display:flex;
    justify-content: center;
    align-items: center;
}
.about .content img{
    
    width:300px;
    max-width:100%;
}
.image{
    width:125px;
    height:470px;
    border-radius:10rem 10rem 1rem 1rem;
    display:flex;
    overflow: hidden;
    
    

}
.text{
    width:550px;
    max-width:100%;
    padding:0 10px;
}
.content {
    width:1280px;
    max-width:95%;
    margin:0 auto;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.text h1{
    color:var(--color-primary);
    font-size:85px;
    margin-bottom: 20px;
    text-transform: capitalize;
}
.text h5{
    color:white;
    font-size:25px;
    margin-bottom: 25px;
    text-transform: capitalize;
    letter-spacing: 2px;
}
.text p{
    color:#c0c4d6;
    font-size:18px;
    line-height: 28px;
    letter-spacing:2px;
    margin-bottom:45px;


}
button{
    width:max-content;
    display:inline-block;
    color:var(--color-primary);
    padding:0.75rem 1.2rem;
    border-radius:0.4rem;
    cursor:pointer;
    border:1px solid var(--color-primary);
    transition:var(--transition);
}
button:hover{ 
    transform: scale(1.2);
    background-color: transparent;
    border: 2px solid #ffcc74;
    color:#ffcc74;
    transition: .4s;
    cursor:pointer;
}
@media screen and (max-width:1180px){
    .about{
        width:100%;
        height:auto;
        padding:70px 0px;
    }
}
@media screen and (max-width:650px){
    .about .content img{
        margin-bottom:35px;
    }
    .text h1{
        font-size:60px;
        margin-bottom:25px;
    }
}