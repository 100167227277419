:root {
    --color-bg: #1f1f38;
    --color-bg-variant:#2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant:rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light:rgba(255,255, 255,0.6);
    --container-width-lg:75%;
    --container-width-md:86%;
    --container-width-sm:90%;
    --transition: all 400ms ease;
 }
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px;
  }
  
  h1{
    margin: 0;
    font-size: 2.5rem;
  }
  
  p{
    font-size: 1.4rem;
    font-weight: medium;
    color: rgb(141, 143, 145);
  }
  
  main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
 
  figure{
    margin: 0;
    position: relative;
  }
  
  .me-img{
    border: 5px solid black;
    border-bottom-left-radius: 50%;
  }
  
  
  @media screen and (min-width:800px) {
    .content{
      flex-direction: row;
      margin: 80px;
    }
  
    h1{
      font-size: 3.5rem;
    }
  
    .cta{
      padding: 20px 30px;
      font-size: 1.2rem;
    }
  
    .buttons{
      margin: 0;
    }
  }